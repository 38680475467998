import MarketTable from "@/components/Markets/MarketTable/index";

var Stomp = require("stompjs");
var SockJS = require("sockjs-client");
import SvgLine from "@/components/Exchange/SvgLine/index";
import {mapGetters, mapActions} from "vuex";
import debounce from "@/utiles/debounce";

export default {
    components: {
        SvgLine,
        MarketTable
    },
    data() {
        return {
            baseCoinType: null,
            selectedBaseCoin: "",
            loading: true,
            percent: 0,
            currentPageFavor: 1,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            FAQList: [],
            yesDayCashDividensBonusETH: 0,
            CNYRate: null,
            dataIndex: [],
            searchKey: "",
            favorColumns: [],
            coins: {
                _map: [],
                favor: [],
                columns: []
            },
            indexBtn: [],
            choseBtn: 1,
            valueCal: 0,
            showArrow: "never",
            speed: 5000,
            symbol: "",
            usdtData: [],
            usdtList: [],
            daiList: [],
            picList: [],
            picShow: false,
            subscriptions: [],
        };
    },
    created() {
        this.init();
    },
    computed: {
        ...mapGetters({
            getLoginStatus: "global/IS_LOGIN",
            getLang: "global/GET_LANG"
        }),
        isLogin() {
            return this.getLoginStatus;
        },
        lang() {
            return this.getLang;
        },
        langPram() {
            if (this.getLang === "English") {
                return "EN";
            }
            return "EN";
        }
    },
    watch: {
        lang() {
            this.updateLangData();
        }
    },
    mounted() {
        this.favorColumns = [
            {
                title: this.$t("marketsPage.coin"),
                align: this.isLocaleLTR ? "left" : "right",
                minWidth: 180,
                key: "coin",
                slot: "coin"
            },
            {
                title: this.$t("marketsPage.newPrice"),
                align: this.isLocaleLTR ? "left" : "right",
                minWidth: 120,
                key: "price",
                sortable: true,
                sortMethod: (a, b, type) => {
                    let a1 = parseFloat(a);
                    let b1 = parseFloat(b);
                    if (type == "asc") {
                        return a1 - b1;
                    } else {
                        return b1 - a1;
                    }
                },
                render: (h, params) => {
                    // var rmb = self.round(self.mul(params.row.price, 6.5), 2);
                    // if (self.CNYRate != null)
                    //   rmb = self.round(self.mul(params.row.price, self.CNYRate), 2);
                    // let CNYRate = self.CNYRate || 6.5,
                    //   rmb = self.round(self.mul(params.row.usdRate, CNYRate), 2);
                    const isgreen =
                        parseFloat(params.row.rose) < 0 ? "none" : "inline-block";
                    const nogreen =
                        parseFloat(params.row.rose) < 0 ? "inline-block" : "none";
                    return h(
                        "div",
                        {
                            attrs: {
                                class: "price-td"
                            }
                        },
                        [
                            h(
                                "span",
                                {},
                                this.numberWithCommas(
                                    params.row.price,
                                    params.row.baseCoinScale
                                )
                            ),
                            // h("span", {
                            //     attrs: {
                            //       class: "price-rmb"
                            //     }
                            //   }, "$"),
                            h(
                                "Icon",
                                {
                                    props: {
                                        type: "arrow-up-c"
                                    },
                                    style: {
                                        display: isgreen,
                                        fontSize: "16px",
                                        marginLeft: "5px",
                                        verticalAlign: "middle"
                                    },
                                    class: {
                                        green: true
                                    }
                                },
                                "↑"
                            ),
                            h(
                                "Icon",
                                {
                                    props: {
                                        type: "arrow-down-c"
                                    },
                                    style: {
                                        display: nogreen,
                                        fontSize: "16px",
                                        marginLeft: "5px",
                                        verticalAlign: "middle"
                                    },
                                    class: {
                                        red: true
                                    }
                                },
                                "↓"
                            )
                        ]
                    );
                }
            },
            {
                title: this.$t("marketsPage.change"),
                align: this.isLocaleLTR ? "left" : "right",
                minWidth: 120,
                key: "rose",
                sortable: true,
                sortMethod: (a, b, type) => {
                    let a1 = a.replace(/[^\d|.|-]/g, "") - 0;
                    let b1 = b.replace(/[^\d|.|-]/g, "") - 0;
                    if (type == "asc") {
                        return a1 - b1;
                    } else {
                        return b1 - a1;
                    }
                },
                render: (h, params) => {
                    const row = params.row;
                    const className =
                        parseFloat(row.rose) >= 0
                            ? parseFloat(row.rose) === 0
                                ? ""
                                : "green"
                            : "red";
                    return h(
                        "div",
                        {
                            style: {
                                direction: "ltr"
                            },
                            attrs: {
                                class: className
                            }
                        },
                        row.rose
                    );
                }
            },
            {
                title: this.$t("marketsPage.high"),
                align: this.isLocaleLTR ? "left" : "right",
                minWidth: 120,
                key: "high",
                render: (h, params) => {
                    return h(
                        "div",
                        {},
                        this.numberWithCommas(params.row.high, params.row.baseCoinScale)
                    );
                }
            },
            {
                title: this.$t("marketsPage.low"),
                align: this.isLocaleLTR ? "left" : "right",
                minWidth: 120,
                key: "low",
                render: (h, params) => {
                    return h(
                        "div",
                        {},
                        this.numberWithCommas(params.row.low, params.row.baseCoinScale)
                    );
                }
            },
            {
                title: this.$t("marketsPage.exchangeNum"),
                align: this.isLocaleLTR ? "left" : "right",
                minWidth: 120,
                key: "volume",
                // width: 110,
                sortable: true,
                sortMethod: (a, b, type) => {
                    let a1 = parseFloat(a);
                    let b1 = parseFloat(b);
                    if (type == "asc") {
                        return a1 - b1;
                    } else {
                        return b1 - a1;
                    }
                },
                render: (h, params) => {
                    return h(
                        "div",
                        {},
                        this.numberWithCommas(params.row.volume, params.row.baseCoinScale)
                    );
                }
            },
            {
                title: this.$t("marketsPage.priceTrend"),
                align: "center",
                minWidth: 100,
                render: (h, params) => {
                    let valus = null;
                    let len = params.row.trend.length;
                    valus =
                        len > 0
                            ? params.row.trend
                            : [
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0
                                // 0,
                                // 0
                            ];
                    return h(SvgLine, {
                        props: {
                            values: valus,
                            rose: params.row.rose,
                            coin: params.row.displayName,
                            width: 100,
                            height: 25
                        }
                    });
                }
            },
            {
                title: this.$t("marketsPage.operate"),
                align: "center",
                minWidth: 100,
                key: "buyBtn",
                render: (h, params) => {
                    return h("div", [
                        h(
                            "button",
                            {
                                props: {
                                    type: "text",
                                    size: "small"
                                },
                                style: {
                                    border: "1px solid #f0ac19",
                                    color: "#f1ac19",
                                    padding: "3px 10px",
                                    borderRadius: "0",
                                    backgroundColor: "transparent",
                                    cursor: "pointer"
                                },
                                on: {
                                    click: () => {
                                        this.goHref(params.row);
                                    }
                                }
                            },
                            this.$t("marketsPage.trading")
                        )
                    ]);
                }
            }
        ]
        this.coins.columns = [
            {
                title: this.$t("marketsPage.coin"),
                align: this.isLocaleLTR ? "left" : "right",
                minWidth: 180,
                key: "coin",
                slot: "coin"
            },
            {
                title: this.$t("marketsPage.newPrice"),
                align: this.isLocaleLTR ? "left" : "right",
                minWidth: 120,
                key: "price",
                sortable: true,
                sortMethod: (a, b, type) => {
                    let a1 = parseFloat(a);
                    let b1 = parseFloat(b);
                    if (type == "asc") {
                        return a1 - b1;
                    } else {
                        return b1 - a1;
                    }
                },
                render: (h, params) => {
                    // var rmb = self.round(self.mul(params.row.price, 6.5), 2);
                    // if (self.CNYRate != null)
                    //   rmb = self.round(self.mul(params.row.price, self.CNYRate), 2);
                    // let CNYRate = self.CNYRate || 6.5,
                    //   rmb = self.round(self.mul(params.row.usdRate, self.CNYRate), 2);
                    const isgreen =
                        parseFloat(params.row.rose) < 0 ? "none" : "inline-block";
                    const nogreen =
                        parseFloat(params.row.rose) < 0 ? "inline-block" : "none";
                    return h(
                        "div",
                        {
                            attrs: {
                                class: "price-td"
                            }
                        },
                        [
                            h(
                                "span",
                                {},
                                this.numberWithCommas(
                                    params.row.price,
                                    params.row.baseCoinScale
                                ) + ""
                            ),
                            h("span", {
                                attrs: {
                                    class: "price-rmb"
                                }
                            }),
                            h(
                                "Icon",
                                {
                                    props: {
                                        type: "arrow-up-c"
                                    },
                                    style: {
                                        display: isgreen,
                                        fontSize: "16px",
                                        marginLeft: "5px",
                                        verticalAlign: "middle"
                                    },
                                    class: {
                                        green: true
                                    }
                                },
                                "↑"
                            ),
                            h(
                                "Icon",
                                {
                                    props: {
                                        type: "arrow-down-c"
                                    },
                                    style: {
                                        display: nogreen,
                                        fontSize: "16px",
                                        marginLeft: "5px",
                                        verticalAlign: "middle"
                                    },
                                    class: {
                                        red: true
                                    }
                                },
                                "↓"
                            )
                        ]
                    );
                }
            },
            {
                title: this.$t("marketsPage.change"),
                align: this.isLocaleLTR ? "left" : "right",
                minWidth: 120,
                key: "rose",
                sortable: true,
                sortMethod: (a, b, type) => {
                    let a1 = a.replace(/[^\d|.|-]/g, "") - 0;
                    let b1 = b.replace(/[^\d|.|-]/g, "") - 0;
                    if (type == "asc") {
                        return a1 - b1;
                    } else {
                        return b1 - a1;
                    }
                },
                render: (h, params) => {
                    const row = params.row;
                    const className =
                        parseFloat(row.rose) >= 0
                            ? parseFloat(row.rose) === 0
                                ? ""
                                : "green"
                            : "red";
                    return h(
                        "div",
                        {
                            style: {
                                direction: "ltr"
                            },
                            attrs: {
                                class: className
                            }
                        },
                        row.rose
                    );
                }
            },
            {
                title: this.$t("marketsPage.high"),
                align: this.isLocaleLTR ? "left" : "right",
                minWidth: 120,
                key: "high",
                render: (h, params) => {
                    return h(
                        "div",
                        {},
                        this.numberWithCommas(params.row.high, params.row.baseCoinScale)
                    );
                }
            },
            {
                title: this.$t("marketsPage.low"),
                align: this.isLocaleLTR ? "left" : "right",
                key: "high",
                minWidth: 120,
                render: (h, params) => {
                    return h(
                        "div",
                        {},
                        this.numberWithCommas(params.row.low, params.row.baseCoinScale)
                    );
                }
            },
            {
                title: this.$t("marketsPage.exchangeNum"),
                align: this.isLocaleLTR ? "left" : "right",
                key: "twentyFourHourTurnover",
                minWidth: 120,
                sortable: true,
                sortMethod: (a, b, type) => {
                    let a1 = parseFloat(a);
                    let b1 = parseFloat(b);
                    if (type == "asc") {
                        return a1 - b1;
                    } else {
                        return b1 - a1;
                    }
                },
                render: (h, params) => {
                    return h(
                        "div",
                        {},
                        this.numberWithCommas(params.row.twentyFourHourTurnover, params.row.baseCoinScale)
                    );
                }
            },
            {
                title: this.$t("marketsPage.priceTrend"),
                align: "center",
                minWidth: 100,
                render: (h, params) => {
                    let valus = null;
                    let len = params.row.trend.length;
                    valus =
                        len > 0
                            ? params.row.trend
                            : [
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0
                                // 0,
                                // 0
                            ];
                    return h(SvgLine, {
                        props: {
                            values: valus,
                            rose: params.row.rose,
                            coin: params.row.displayName,
                            width: 100,
                            height: 25
                        }
                    });
                }
            },
            {
                title: this.$t("marketsPage.operate"),
                align: "center",
                key: "buyBtn",
                minWidth: 100,
                render: (h, params) => {
                    return h("div", [
                        h(
                            "button",
                            {
                                props: {
                                    type: "text",
                                    size: "small"
                                },
                                style: {
                                    border: "1px solid #f0ac19",
                                    color: "#f1ac19",
                                    padding: "3px 10px",
                                    borderRadius: "0",
                                    backgroundColor: "transparent",
                                    cursor: "pointer"
                                },
                                on: {
                                    click: () => {
                                        this.$router.push({
                                            name: "ExchangePair",
                                            params: {
                                                pair: params.row.href
                                            }
                                        });
                                    }
                                }
                            },
                            this.$t("marketsPage.trading")
                        )
                    ]);
                }
            }
        ]
        this.getBaseSymbols();
    },
    methods: {
        ...mapActions({
            setNavigation: "global/NAVIGATE",
            getBaseSymbolsList: "market/GET_BASE_SYMBOLS_SERVICE",
            getSymbolsThumbTrend: "market/GET_SYMBOL_THUMB_TREND_SERVICE",
            getFavoredMarkets: "exchange/GET_FAVORED_MARKETS_SERVICE",
            addFavoredMarkets: "exchange/ADD_FAVORED_MARKET_SERVICE",
            deleteFavoredMarkets: "exchange/DELETE_FAVORED_MARKET_SERVICE"
        }),
        searchInputChange: function () {
            this.searchKey = this.searchKey.toUpperCase();
            this.debounceSearch()
        },
        debounceSearch: debounce(function () {
            this.onChangeBase(this.selectedBaseCoin, this.choseBtn)
        }, 500),
        updateLangData() {
            this.indexBtn = [
                {
                    text: this.$t("service.custom")
                },
                {
                    text: this.$t("service.USDT")
                },
                {
                    text: this.$t("service.DAI")
                },
                {
                    text: this.$t("service.BTCB")
                },
                {
                    text: this.$t("service.ETH")
                }
                // {
                //   text: this.$t("service.BNB")
                // }
            ];

            this.coins.columns[0].title = this.$t("service.favor");
            this.coins.columns[1].title = this.$t("service.COIN");
            this.coins.columns[2].title = this.$t("service.NewPrice");
            this.coins.columns[3].title = this.$t("service.Change");
            this.coins.columns[4].title = this.$t("service.high");
            this.coins.columns[5].title = this.$t("service.low");
            this.coins.columns[6].title = this.$t("service.ExchangeNum");
            this.coins.columns[7].title = this.$t("service.PriceTrend");
            this.coins.columns[8].title = this.$t("service.Operate");

            this.favorColumns[0].title = this.$t("service.favor");
            this.favorColumns[1].title = this.$t("service.COIN");
            this.favorColumns[2].title = this.$t("service.NewPrice");
            this.favorColumns[3].title = this.$t("service.Change");
            this.favorColumns[4].title = this.$t("service.high");
            this.favorColumns[5].title = this.$t("service.low");
            this.favorColumns[6].title = this.$t("service.ExchangeNum");
            this.favorColumns[7].title = this.$t("service.PriceTrend");
            this.favorColumns[8].title = this.$t("service.Operate");
        },
        init() {
            this.setNavigation("nav-markets");
            // this.$store.state.HeaderActiveName = "1-1";
            // this.addClass(1);
        },
        getStyle(obj, attr) {
            if (obj.currentStyle) {
                return obj.currentStyle[attr];
            } else {
                return getComputedStyle(obj, false)[attr];
            }
        },
        getCoin(symbol) {
            return this.coins._map[symbol];
        },
        startWebsock() {
            // unsubscribe last subscribe
            this.subscriptions.forEach(sub => sub.unsubscribe())
            this.subscriptions = []

            var stompClient = null;
            var that = this;
            var socket = new SockJS(that.host + that.api.market.ws);
            stompClient = Stomp.over(socket);
            stompClient.debug = false;
            stompClient.connect({}, function (frame) {
                //subscribe to price change news
                that.dataIndex.forEach(({symbol}) => {
                    const subscription = stompClient.subscribe(`/topic/market/thumb/${symbol}`, function (msg) {
                        var resp = JSON.parse(msg.body);
                        var coin = that.getCoin(resp.symbol);
                        if (coin != null) {
                            // coin.price = resp.close.toFixed(2);
                            coin.price = resp.close;
                            coin.rose =
                                resp.chg > 0
                                    ? "+" + (resp.chg * 100).toFixed(2) + "%"
                                    : (resp.chg * 100).toFixed(2) + "%";
                            // coin.close = resp.close.toFixed(2);
                            // coin.high = resp.high.toFixed(2);
                            // coin.low = resp.low.toFixed(2);
                            coin.close = resp.close;
                            coin.high = resp.high;
                            coin.low = resp.low;
                            coin.twentyFourHourTurnover = resp.twentyFourHourTurnover;
                            coin.turnover = parseInt(resp.volume);
                        }
                    });
                    // push subscription for Unsubscription
                    that.subscriptions.push(subscription)
                })
            });
        },
        round(v, e) {
            var t = 1;
            for (; e > 0; t *= 10, e--) ;
            for (; e < 0; t /= 10, e++) ;
            return Math.round(v * t) / t;
        },
        mul(a, b) {
            var c = 0,
                d = a.toString(),
                e = b.toString();
            try {
                c += d.split(".")[1].length;
            } catch (f) {
            }
            try {
                c += e.split(".")[1].length;
            } catch (f) {
            }
            return (
                (Number(d.replace(".", "")) * Number(e.replace(".", ""))) /
                Math.pow(10, c)
            );
        },
        getBaseSymbols() {
            this.getBaseSymbolsList().then((response) => {
                this.indexBtn = response.data.data.map(item => {
                    return item.unit;
                });
                this.baseCoinType = response.data.data;
                if (!this.indexBtn.length) {
                    this.loading = false;
                    return;
                }
                this.choseBtn = 1;
                this.indexBtn.forEach((el) => {
                    this.coins[el] = [];
                });
                this.indexBtn.unshift(this.$t("marketsPage.custom"));
                this.baseCoinType.unshift({
                    unit: this.$t("marketsPage.custom"),
                    localedUnit: this.$t("marketsPage.custom"),
                    coinType: "ORDINARY"
                });
                // this.getSymbol();
                const baseCoin = this.isLogin ? this.$t("marketsPage.custom") : "USDT"
                const BaseIndex = this.isLogin ? 0 : 1
                this.onChangeBase(baseCoin, BaseIndex)
            }).finally(() => this.loading = false)
        },
        onFavoritesPageChange(pageIndex) {
            this.currentPageFavor = pageIndex
            this.onChangeBase(this.selectedBaseCoin, this.choseBtn)
        },
        onChangePage(pageIndex) {
            this.currentPage = pageIndex
            this.onChangeBase(this.selectedBaseCoin, this.choseBtn)
        },
        onChangeBase(baseCoin, index) {
            this.choseBtn = index;
            this.selectedBaseCoin = baseCoin
            let data = {
                baseSymbol: baseCoin,
                coinSymbol: this.searchKey,
                pageSize: this.pageSize,
                pageNo: index === 0 ? this.currentPageFavor : this.currentPage,
            }
            if (index===0){
                this.getFavor({symbol:this.searchKey})
            }else {
                this.getSymbol(data)
                this.currentPage=1
            }
        },
        // New Symbol
        getSymbol(data) {
            this.getSymbolsThumbTrend(data).then((response) => {
                this.dataIndex = response.data.data.content
                this.total = response.data.data.totalPages
                const result = response.data.data.content
                for (let i = 0; i < result.length; i++) {
                    let coin = result[i];
                    coin.price = result[i].close;
                    coin.rose =
                        result[i].chg > 0
                            ? "+" + (result[i].chg * 100).toFixed(2) + "%"
                            : (result[i].chg * 100).toFixed(2) + "%";
                    coin.coin = result[i].symbol.split("/")[0];
                    coin.base = result[i].symbol.split("/")[1];
                    coin.href = (coin.coin + "_" + coin.base).toLowerCase();
                    coin.coinScale = result[i].coinScale;
                    coin.baseCoinScale = result[i].baseCoinScale;
                    coin.isFavor = false;
                    this.coins._map[coin.symbol] = coin;
                    if (coin.base && this.indexBtn.includes(coin.base)) {
                        if (coin.zone === 0) {
                            this.coins[coin.base].push(coin); // Motherboard
                        } else {
                            this.coins[coin.base + "2"].push(coin); // Innovative version
                        }
                    }
                }
                this.startWebsock()
            })
        },
        // Comment by mohseni
        // getSymbol() {
        //     this.loading = true;
        //     this.getSymbolsThumbTrend().then((response) => {
        //         let result = response.data;
        //         for (let i = 0; i < result.length; i++) {
        //             let coin = result[i];
        //             coin.price = result[i].close;
        //             coin.rose =
        //                 result[i].chg > 0
        //                     ? "+" + (result[i].chg * 100).toFixed(2) + "%"
        //                     : (result[i].chg * 100).toFixed(2) + "%";
        //             coin.coin = result[i].symbol.split("/")[0];
        //             coin.base = result[i].symbol.split("/")[1];
        //             coin.href = (coin.coin + "_" + coin.base).toLowerCase();
        //             coin.coinScale = result[i].coinScale;
        //             coin.baseCoinScale = result[i].baseCoinScale;
        //             coin.isFavor = false;
        //             this.coins._map[coin.symbol] = coin;
        //             if (coin.base && this.indexBtn.includes(coin.base)) {
        //                 if (coin.zone === 0) {
        //                     this.coins[coin.base].push(coin); // Motherboard
        //                 } else {
        //                     this.coins[coin.base + "2"].push(coin); // Innovative version
        //                 }
        //             }
        //         }
        //
        //         this.onChangeBase(this.indexBtn[1], 1);
        //         if (this.isLogin) {
        //             this.getFavor();
        //         }
        //         this.startWebsock();
        //     })
        //         .finally(() => {
        //             this.loading = false;
        //         });
        // },
        // END
        getFavor(data) {
            this.getFavoredMarkets(data).then((response) => {
                this.dataIndex=response.data.data
                this.total = response.data.totalPages
                const result = response.data.data
                for (let i = 0; i < result.length; i++) {
                    let coin = result[i];
                    coin.price = result[i].close;
                    coin.rose =
                        result[i].chg > 0
                            ? "+" + (result[i].chg * 100).toFixed(2) + "%"
                            : (result[i].chg * 100).toFixed(2) + "%";
                    coin.coin = result[i].symbol.split("/")[0];
                    coin.base = result[i].symbol.split("/")[1];
                    coin.href = (coin.coin + "_" + coin.base).toLowerCase();
                    coin.coinScale = result[i].coinScale;
                    coin.baseCoinScale = result[i].baseCoinScale;
                    coin.isFavor = false;
                    this.coins._map[coin.symbol] = coin;
                    if (coin.base && this.indexBtn.includes(coin.base)) {
                        if (coin.zone === 0) {
                            this.coins[coin.base].push(coin); // Motherboard
                        } else {
                            this.coins[coin.base + "2"].push(coin); // Innovative version
                        }
                    }
                }
                this.startWebsock()
                // let favoredCoinsData = response.data.data;
                // this.coins.favor = [];
                // for (let i = 0; i < favoredCoinsData.length; i++) {
                //     let coin = this.getCoin(favoredCoinsData[i].symbol);
                //     if (coin != null) {
                //         coin.isFavor = true;
                //         this.coins.favor.push(coin);
                //     }
                // }
            });
        },
        collect(index, row) {
            if (!this.isLogin) {
                this.$toast.warning(this.$t("common.loginTip"));
                return;
            }
            let payload = {
                symbol: row.symbol
            };
            this.addFavoredMarkets(payload)
                .then((response) => {
                    if (response.data.code === 0) {
                        this.$toast.success(this.$t("marketsPage.addToFavorites"));
                        this.getCoin(row.symbol).isFavor = true;
                        row.isFavor = true;
                        this.coins.favor.push(row);
                    }
                })
                .catch((err) => {
                    this.$toast.error(err.message);
                });
        },
        cancelCollect(index, row) {
            if (!this.isLogin) {
                this.$toast.warning(this.$t("common.loginTip"));
                return;
            }
            let payload = {
                symbol: row.symbol
            };
            this.deleteFavoredMarkets(payload)
                .then((response) => {
                    if (response.data.code === 0) {
                        this.$toast.success(this.$t("marketsPage.removeFavorites"));
                        this.getCoin(row.symbol).isFavor = false;
                        for (let i = 0; i < this.coins.favor.length; i++) {
                            let favorCoin = this.coins.favor[i];
                            if (favorCoin.symbol == row.symbol) {
                                this.coins.favor.splice(i, 1);
                                break;
                            }
                        }
                    }
                })
                .catch((err) => {
                    this.$toast.error(err.message);
                });
        },
        goHref(currentRow) {
            this.$router.push(
                {
                    name: "ExchangePair",
                    params: {
                        pair: currentRow.href
                    }
                },
                () => {
                }
            );
        }
    }
}
;

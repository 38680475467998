<template>
  <div class="container">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #F1AC1C;
  margin: 0 2px;
  animation: jump 1.1s infinite ;
}


.container .dot:nth-last-child(1) {
  animation-delay: 0.1s;
}
.container .dot:nth-last-child(2) {
  animation-delay: 0.2s;
}
.container .dot:nth-last-child(3) {
  animation-delay: 0.3s;
}

@keyframes jump {
  0%   {
    transform: translateY(0px);
  }
  35%  {
    transform: translateY(0px);
  }
  50%  {
    transform: translateY(-8px);
  }
  70%  {
    transform: translateY(3px);
  }
  85%  {
    transform: translateY(-3px);
  }
}
</style>
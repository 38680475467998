import { render, staticRenderFns } from "./Markets.html?vue&type=template&id=a05241ba&scoped=true&"
import script from "./Markets.js?vue&type=script&lang=js&"
export * from "./Markets.js?vue&type=script&lang=js&"
import style0 from "./markets.scss?vue&type=style&index=0&id=a05241ba&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a05241ba",
  null
  
)

export default component.exports
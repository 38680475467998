import Loader from "@/components/UI/LoadingComp"

export default {
    name: "market-table",
    components: {
        Loader
    },
    props: {
        dataTable: {
            type: Array,
        },
        tableHeader: {
            type: Array,
        },
        loading: {
            type: Boolean,
        },
        chooseBtn: {
            type: Number,
        },
        pageSize: {
            type: Number,
        },
        total: {
            type: Number,
        },
        currentPage: {
            type: Number,
        },
        currentPageFavor: {
            type: Number,
        },
        // current: {
        //   type: Number
        // },
        // total: {
        //   type: Number
        // },
        // pageSize: {
        //   type: Number
        // },
        // currentFavorite: {
        //   type: Number
        // },
        // totalFavorite: {
        //   type: Number
        // },
        // pageSizeFavorite: {
        //   type: Number
        // },
    },
    data() {
        return {};
    },
    computed: {},
    mounted() {
    },
    methods: {
        goHref(val) {
            this.$emit("goHref", val);
        },
        emitCollect(event, type, index, row) {
            event.stopPropagation();
            this.$emit(type, index, row);
        },
        setDefaultLogo(e) {
            e.target.src = require("@/assets/images/coin_place_holder.png");
        },
        changePageNumber(pageIndex) {
          this.$emit("changePage", pageIndex);
        },
        changeFavoritesPageNumber(pageIndex) {
          this.$emit("changeFavoritesPage", pageIndex);
        },
    },
};
